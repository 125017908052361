<template>
  <div class="sidebar">
    <div class="sidebar__header">
      <span class="fs-4">Projects</span>
    </div>
    <div class="sidebar__divider sidebar__divider_padding"></div>
    <div class="sidebar__content">
      <div class="list-group list-group-flush border-bottom scrollarea sidebar__list">
        <router-link v-for="p in projects"
                     :key="p.jiraKey"
                     :to="{ name: 'project', params:{id: p.jiraKey} }"
                     active-class="active"
                     class="list-group-item list-group-item-action py-3 lh-tight">
          <div class="d-flex w-100 align-items-center justify-content-between">
            <strong class="mb-1">{{ p.jiraKey }}</strong>
          </div>
          <div class="col-10 mb-1 small">{{ p.jiraBaseUrl }}</div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="content">
    <router-view/>
  </div>
</template>

<style>
.sidebar__list {
  width: 300px;
}
</style>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  computed: mapState({
    projects: state => state.projects.projects
  }),
  methods: mapActions('projects', [
    'loadProjects'
  ]),
  beforeMount() {
    this.loadProjects();
  }
}
</script>